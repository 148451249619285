<template>
  <nav class="navbar navbar-expand-lg c-bg">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <router-link to="/" class="navbar-brand mx-2 logotipo">
          <svg width="200" height="50" viewBox="0 0 452 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M154.152 91.7585L142.247 49.5767H150.465L158.065 80.5746H158.457L166.572 49.5767H174.048L182.184 80.5952H182.555L190.155 49.5767H198.373L186.468 91.7585H178.93L170.485 62.1612H170.155L161.69 91.7585H154.152Z" fill="#121212"/>
            <path d="M203.671 91.7585V49.5767H231.106V55.9822H211.313V67.434H229.685V73.8395H211.313V85.353H231.271V91.7585H203.671Z " fill="#121212"/>
            <path d="M238.902 91.7585V49.5767H255.049C258.098 49.5767 260.631 50.0573 262.65 51.0185C264.682 51.9659 266.199 53.2635 267.201 54.9112C268.218 56.5589 268.726 58.4264 268.726 60.5135C268.726 62.2299 268.396 63.6991 267.737 64.9212C267.078 66.1295 266.192 67.1113 265.08 67.8665C263.968 68.6217 262.725 69.1641 261.352 69.4936V69.9055C262.849 69.9879 264.284 70.4479 265.657 71.2855C267.044 72.1094 268.176 73.2765 269.055 74.7869C269.934 76.2973 270.373 78.1236 270.373 80.2656C270.373 82.4489 269.845 84.4124 268.787 86.1562C267.73 87.8864 266.137 89.2526 264.009 90.255C261.881 91.2573 259.203 91.7585 255.976 91.7585H238.902ZM246.543 85.3736H254.761C257.535 85.3736 259.533 84.8449 260.755 83.7876C261.991 82.7166 262.608 81.3435 262.608 79.6683C262.608 78.4188 262.299 77.2929 261.682 76.2905C261.064 75.2744 260.185 74.478 259.045 73.9013C257.906 73.3108 256.546 73.0156 254.967 73.0156H246.543V85.3736ZM246.543 67.5163H254.102C255.42 67.5163 256.608 67.276 257.665 66.7955C258.723 66.3011 259.553 65.6077 260.157 64.7152C260.775 63.8089 261.084 62.7379 261.084 61.5021C261.084 59.8681 260.508 58.5225 259.354 57.4652C258.214 56.4079 256.519 55.8793 254.267 55.8793H246.543V67.5163Z" fill="#121212"/>
            <path d="M306.134 92.3352C303.168 92.3352 300.532 91.8272 298.225 90.8111C295.932 89.795 294.119 88.3807 292.788 86.5682C291.456 84.7557 290.748 82.6617 290.666 80.2862H298.41C298.479 81.4259 298.857 82.4214 299.543 83.2727C300.23 84.1103 301.143 84.7625 302.283 85.2294C303.422 85.6963 304.699 85.9297 306.114 85.9297C307.624 85.9297 308.963 85.6688 310.13 85.147C311.297 84.6115 312.21 83.87 312.869 82.9226C313.528 81.9751 313.851 80.8835 313.837 79.6477C313.851 78.3707 313.521 77.2448 312.849 76.2699C312.176 75.295 311.201 74.5329 309.924 73.9837C308.661 73.4344 307.137 73.1598 305.351 73.1598H301.623V67.2692H305.351C306.821 67.2692 308.105 67.0152 309.203 66.5071C310.315 65.9991 311.187 65.285 311.819 64.3651C312.45 63.4313 312.759 62.3535 312.746 61.1314C312.759 59.9368 312.492 58.9001 311.942 58.0213C311.407 57.1288 310.645 56.4354 309.656 55.9411C308.681 55.4467 307.535 55.1996 306.217 55.1996C304.926 55.1996 303.731 55.433 302.633 55.8999C301.534 56.3667 300.649 57.0327 299.976 57.8977C299.303 58.7491 298.946 59.7652 298.905 60.946H291.552C291.607 58.5843 292.286 56.5109 293.591 54.7259C294.909 52.9271 296.667 51.5265 298.864 50.5241C301.061 49.508 303.525 49 306.258 49C309.073 49 311.517 49.5286 313.59 50.5859C315.677 51.6295 317.291 53.0369 318.43 54.8082C319.57 56.5795 320.14 58.5362 320.14 60.6783C320.154 63.0537 319.453 65.0447 318.039 66.6513C316.638 68.2578 314.798 69.3082 312.519 69.8026V70.1321C315.485 70.544 317.757 71.6425 319.337 73.4276C320.929 75.1989 321.719 77.4027 321.705 80.0391C321.705 82.4008 321.032 84.5154 319.687 86.3828C318.355 88.2365 316.515 89.692 314.167 90.7493C311.833 91.8066 309.155 92.3352 306.134 92.3352Z" fill="#121212"/>
            <path d="M344.222 92.3352C342.204 92.3215 340.234 91.9714 338.311 91.2848C336.389 90.5845 334.659 89.4517 333.121 87.8864C331.583 86.3073 330.361 84.2133 329.455 81.6044C328.548 78.9818 328.102 75.7344 328.116 71.8622C328.116 68.2509 328.5 65.031 329.269 62.2024C330.038 59.3738 331.144 56.9846 332.585 55.0348C334.027 53.0713 335.764 51.5746 337.796 50.5447C339.842 49.5149 342.128 49 344.655 49C347.305 49 349.653 49.5218 351.699 50.5653C353.759 51.6089 355.42 53.0369 356.683 54.8494C357.947 56.6482 358.729 58.6804 359.031 60.946H351.514C351.129 59.3258 350.34 58.035 349.145 57.0739C347.964 56.099 346.467 55.6115 344.655 55.6115C341.73 55.6115 339.478 56.8816 337.899 59.4219C336.334 61.9621 335.544 65.4498 335.531 69.8849H335.819C336.492 68.6766 337.364 67.6399 338.435 66.7749C339.506 65.9098 340.714 65.2438 342.06 64.777C343.419 64.2964 344.854 64.0561 346.364 64.0561C348.836 64.0561 351.054 64.6465 353.017 65.8274C354.994 67.0083 356.56 68.6354 357.713 70.7088C358.867 72.7685 359.436 75.1302 359.423 77.794C359.436 80.5677 358.805 83.0599 357.528 85.2706C356.251 87.4676 354.473 89.1977 352.193 90.4609C349.914 91.7242 347.257 92.349 344.222 92.3352ZM344.181 86.1562C345.678 86.1562 347.017 85.7924 348.198 85.0646C349.378 84.3369 350.312 83.3551 350.999 82.1193C351.685 80.8835 352.022 79.4967 352.008 77.9588C352.022 76.4484 351.692 75.0822 351.019 73.8601C350.36 72.638 349.447 71.67 348.28 70.956C347.113 70.242 345.781 69.8849 344.284 69.8849C343.172 69.8849 342.135 70.0978 341.174 70.5234C340.213 70.9491 339.375 71.5395 338.661 72.2947C337.947 73.0362 337.384 73.9013 336.972 74.8899C336.574 75.8648 336.368 76.9084 336.354 78.0206C336.368 79.4898 336.712 80.8423 337.384 82.0781C338.057 83.3139 338.984 84.3026 340.165 85.044C341.346 85.7855 342.685 86.1562 344.181 86.1562Z" fill="#121212"/>
            <path d="M381.976 92.5618C378.584 92.5618 375.673 91.7036 373.243 89.9872C370.826 88.2571 368.966 85.7649 367.661 82.5107C366.371 79.2427 365.725 75.3087 365.725 70.7088C365.739 66.1089 366.391 62.1955 367.682 58.9688C368.986 55.7282 370.847 53.2566 373.264 51.554C375.694 49.8513 378.598 49 381.976 49C385.354 49 388.258 49.8513 390.688 51.554C393.119 53.2566 394.979 55.7282 396.27 58.9688C397.574 62.2093 398.227 66.1226 398.227 70.7088C398.227 75.3224 397.574 79.2633 396.27 82.5312C394.979 85.7855 393.119 88.2708 390.688 89.9872C388.272 91.7036 385.368 92.5618 381.976 92.5618ZM381.976 86.1151C384.612 86.1151 386.693 84.8175 388.217 82.2223C389.755 79.6134 390.524 75.7756 390.524 70.7088C390.524 67.3584 390.173 64.5436 389.473 62.2642C388.773 59.9848 387.784 58.2685 386.507 57.1151C385.23 55.9479 383.72 55.3643 381.976 55.3643C379.353 55.3643 377.28 56.6688 375.756 59.2777C374.232 61.8729 373.463 65.6832 373.449 70.7088C373.435 74.0729 373.772 76.9015 374.458 79.1946C375.158 81.4877 376.147 83.2178 377.424 84.3849C378.701 85.5384 380.218 86.1151 381.976 86.1151Z" fill="#121212"/>
            <path d="M414.606 69.0817C412.78 69.0817 411.118 68.6423 409.622 67.7635C408.125 66.871 406.93 65.6764 406.038 64.1797C405.145 62.6693 404.699 61.0009 404.699 59.1747C404.699 57.3622 405.145 55.7076 406.038 54.2109C406.93 52.7005 408.125 51.5059 409.622 50.6271C411.118 49.7346 412.78 49.2884 414.606 49.2884C416.432 49.2884 418.094 49.7346 419.59 50.6271C421.087 51.5059 422.282 52.7005 423.174 54.2109C424.067 55.7076 424.513 57.3622 424.513 59.1747C424.513 61.0009 424.067 62.6693 423.174 64.1797C422.282 65.6764 421.087 66.871 419.59 67.7635C418.094 68.6423 416.432 69.0817 414.606 69.0817ZM414.606 63.8089C415.457 63.8089 416.233 63.603 416.934 63.1911C417.634 62.7791 418.19 62.223 418.602 61.5227C419.028 60.8224 419.24 60.0398 419.24 59.1747C419.24 58.3234 419.028 57.5476 418.602 56.8473C418.19 56.147 417.634 55.5909 416.934 55.179C416.233 54.767 415.457 54.5611 414.606 54.5611C413.755 54.5611 412.979 54.767 412.279 55.179C411.578 55.5909 411.015 56.147 410.59 56.8473C410.178 57.5476 409.972 58.3234 409.972 59.1747C409.972 60.026 410.178 60.8087 410.59 61.5227C411.002 62.223 411.558 62.7791 412.258 63.1911C412.972 63.603 413.755 63.8089 414.606 63.8089Z" fill="#121212"/>
            <path d="M53.7826 27H27V53.7826H53.7826V27Z" fill="#121212"/>
            <path d="M115 88.2173H88.2173V115H115V88.2173Z" fill="#121212"/>
            <path d="M53.7826 88.2173H27V115H53.7826V88.2173Z" fill="#121212"/>
            <path d="M115 27H88.2173V53.7826H115V27Z" fill="#121212"/>
            <path d="M84.3915 27H57.6089V53.7826H84.3915V27Z" fill="#121212"/>
            <path d="M53.7826 57.6086H27V84.3913H53.7826V57.6086Z" fill="#121212"/>
            <path d="M115 57.6086H88.2173V84.3913H115V57.6086Z" fill="#121212"/>
            <path d="M84.3915 57.6086H57.6089V84.3913H84.3915V57.6086Z" fill="#121212"/>
            <path d="M84.3915 88.2173H57.6089V115H84.3915V88.2173Z" fill="#121212"/>
            </svg>
        </router-link>
      </a>
      <!-- <ThemeButton /> -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <router-link class="nav-link" to="/" v-if="usuarioAutenticado">
            <a class="nav-link " :class="{ active: currentRoute == 'home'}" aria-current="page" href="#">
              <span class="c-color"> Inicio</span>
            </a>
          </router-link>
          <router-link class="nav-link" to="/settings" v-if="usuarioAutenticado">
            <a class="nav-link" :class="{ active: currentRoute == 'settings'}" aria-current="page" href="#">
              <span class="c-color">Configuración</span>
            </a>
          </router-link>
          <router-link class="nav-link" :class="{ active: currentRoute == 'edit'}" to="/ingreso"
            v-if="!usuarioAutenticado">
            <a class="nav-link active" aria-current="page" href="#">
              <span class="c-color">Ingresar</span>
            </a>
          </router-link>

          <a class="nav-link">
            <button class="btn nav-link" v-if="usuarioAutenticado" @click="cerrarSesion">
              <span class="c-color">Cerrar Sesión</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ThemeButton from './ThemeButton.vue'
export default {
  components: {
    ThemeButton
  },
  computed: {
    ...mapGetters(['usuarioAutenticado']),
    ...mapState(['currentRoute'])
  },
  methods: {
    ...mapActions(['cerrarSesion'])
  }
}
</script>

