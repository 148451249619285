require('dotenv').config()

import { createStore } from "vuex";
import router from "../router";

export default createStore({
  state: {
    user: null,
    tareas: [],
    searchResult: [],
    tarea: {
      id: "",
      manzana: "",
      lote: "",
      area: 0,
      estado: "",
    },
    manzanas: [],
    states: [
      'reservado',
      'disponible',
      'vendido'
    ],
    contactInfo: null,
    contactInfoTemp: null,
    index: 0,
    error: { tipo: null, mensaje: null },
    emailRemembered: "",
    currentRoute: 'home',
  },
  mutations: {
    setError(state, payload) {
      if (payload === null) {
        return (state.error = { tipo: null, mensaje: null });
      }
      if (payload === "EMAIL_NOT_FOUND") {
        return (state.error = {
          tipo: "email",
          mensaje: "Email no registrado",
        });
      }
      if (payload === "INVALID_PASSWORD") {
        return (state.error = {
          tipo: "password",
          mensaje: "Contraseña incorrecta",
        });
      }
      if (payload === "EMAIL_EXISTS") {
        return (state.error = {
          tipo: "email",
          mensaje: "Email ya registrado",
        });
      }
      if (payload === "INVALID_EMAIL") {
        return (state.error = {
          tipo: "email",
          mensaje: "Formato incorrecto de email",
        });
      }
    },
    setErrorForgotPassword(state, payload) {
      if (payload === null) {
        return (state.error = { tipo: null, mensaje: null });
      }
      if (payload === "EMAIL_NOT_FOUND") {
        return (state.error = {
          tipo: "email",
          mensaje: "Si el email es correcto revise su bandeja de entrada o buzón de correo no deseado",
        });
      }
    },
    setUser(state, payload) {
      state.user = payload;
    },
    cargar(state, payload) {
      state.tareas = payload;
      state.searchResult = payload;
    },
    setManzanas(state, payload) {
      const setManzanas = new Set(payload);
      state.manzanas = setManzanas;
    },
    cargarContactInfo(state, payload) {
      state.contactInfo = payload;
      state.contactInfoTemp = payload;
    },
    set(state, payload) {
      state.tareas.push(payload);
    },
    eliminar(state, payload) {
      state.tareas = state.tareas.filter((item) => item.id !== payload);
    },
    tarea(state, payload) {
      if (!state.tareas.find((item) => item.id == payload)) {
        router.push("/");
        return;
      }
      state.tarea = state.tareas.find((item) => item.id == payload);
    },
    index(state, payload) {
      state.index = state.tareas.findIndex(e => e.id == payload);
    },
    update(state, payload) {
      state.tareas = state.tareas.map((item) =>
        item.id == payload.id ? payload : item
      );
      router.push("/");
    },
    contactInfoTemp(state, payload) {
      state.contactInfoTemp = Object.assign({}, payload);
    },
    filtrar(state, payload) {
      const { manzana, estado } = payload;
      try {
        
        if (manzana == "all" && estado == "all") {
          state.searchResult = state.tareas;
          return;
        }

        state.searchResult = state.tareas.filter(function(item) {
          
          return (manzana == "all" ? true : item.manzana === manzana) && 
          (estado == "all" ? true : item.estado === estado);
          
        });

      } catch (error) {
        // console.log(error);
      }
    },
    setEmailRemembered(state, payload) {
      state.emailRemembered = payload;
    },
    currentRoute(state, payload) {
      state.currentRoute = payload;
    }

  },
  actions: {
    setFilter({ commit }, value) {
      commit("filtrar", value);
    },
    cerrarSesion({ commit }) {
      commit("setUser", null);
      router.push("/ingreso");
      localStorage.removeItem("usuario");
    },
    async getUserData({ commit }, data) {
      // const { idToken } = data;
      try {
        const res = await fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyDKC5pDB3vcEGtqaSMOGaM08YLfP3oRp_A",
          {
            method: "POST",
            body: JSON.stringify({
              idToken: this.state.user.idToken
            })
          })
        const userDB = await res.json();
        
        if (userDB.error) {
          commit("setUser", null);
          router.push("/ingreso");
          localStorage.removeItem("usuario");
        }
      } catch (error) {
        // TODO
        commit("setUser", null);
        router.push("/ingreso");
        localStorage.removeItem("usuario");
      }
    },
    async passwordReset({ commit }, data) {
      const { email } = data;
      try {
        const res = await fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyDKC5pDB3vcEGtqaSMOGaM08YLfP3oRp_A",
        {
          method: "POST",
          body: JSON.stringify({
            requestType: "PASSWORD_RESET",
            email: email,
          }),
        })
        
        commit("setErrorForgotPassword", res.error)
      } catch (error) {
        
      }
    },
    async ingresoUsuario({ commit }, data) {
      const {usuario, rememberEmail } = data;

      try {
        const res = await fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyDKC5pDB3vcEGtqaSMOGaM08YLfP3oRp_A",
          {
            method: "POST",
            body: JSON.stringify({
              email: usuario.email,
              password: usuario.password,
              returnSecureToken: true,
            }),
          }
        );
        const userDB = await res.json();
        
        if (userDB.error) {
          // console.log(userDB.error);
          return commit("setError", userDB.error.message);
        }
        commit("setUser", userDB);
        commit("setError", null);
        if (rememberEmail) {
          commit("setEmailRemembered", usuario.email);
          localStorage.setItem("email", JSON.stringify(usuario.email))
        } else {
          commit("setEmailRemembered", "");
          localStorage.removeItem("email");
        }
        router.push("/");
        localStorage.setItem("usuario", JSON.stringify(userDB));
      } catch (error) {
        // console.log(error);
      }
    },
    async registrarUsuario({ commit }, usuario) {
      try {
        const res = await fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCVGW1oU1dFf04HU5bST8pX2ACBZCnZPYM",
          {
            method: "POST",
            body: JSON.stringify({
              email: usuario.email,
              password: usuario.password,
              returnSecureToken: true,
            }),
          }
        );
        const userDB = await res.json();
        if (userDB.error) {
          return commit("setError", userDB.error.message);
        }
        commit("setUser", userDB);
        commit("setError", null);
        router.push("/");
      } catch (error) {
        // console.log(error);
      }
    },
    async cargarLocalStorage({ commit }) {
      if (localStorage.getItem("usuario")) {
        commit("setUser", JSON.parse(localStorage.getItem("usuario")));
      } else {
        return commit("setUser", null);
      }
      try {
        const res = await fetch(
          `https://fbase-lienzo-anden-default-rtdb.firebaseio.com/data.json`
        );
        const dataDB = await res.json();
        const arrayTareas = [];
        const manzanas = [];
        const contactInfo = dataDB.infoContacto;
        for (let id in dataDB.scenes[0].infoHotspots) {
          arrayTareas.push(dataDB.scenes[0].infoHotspots[id]);
          manzanas.push(dataDB.scenes[0].infoHotspots[id].manzana)
        }
        
        commit("cargar", arrayTareas);
        commit("setManzanas", manzanas);
        commit("cargarContactInfo", contactInfo);
      } catch (error) {
        // console.log(error);
      }
    },
    async setTareas({ commit, state }, tarea) {
      try {
        const res = await fetch(
          `https://fbase-lienzo-anden-default-rtdb.firebaseio.com/data/scenes/0/infoHotspots/${tarea.id}.json`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(tarea),
          }
        );

        const dataDB = await res.json();
      } catch (error) {
        // console.log(error);
      }
      commit("set", tarea);
    },
    async deleteTareas({ commit }, id) {
      var index = state.tareas.findIndex((item) => item.id == id);

      try {
        await fetch(
          `https://fbase-lienzo-default-rtdb.firebaseio.com/proyectos/ontario-data-db/${index}.json`,
          {
            method: "DELETE",
          }
        );
        commit("eliminar", id);
      } catch (error) {
        // console.log(error);
      }
    },
    setTarea({ commit }, id) {
      commit("tarea", id);
      commit("index", id);
    },
    setError({ commit }, data) {
      commit("setError". data);
    },
    setContactInfo({commit}, payload) {
      commit("contactInfoTemp", payload);
    },
    async updateTarea({ commit }, object) {
      try {
        const res = await fetch(
          `https://fbase-lienzo-anden-default-rtdb.firebaseio.com/data/scenes/0/infoHotspots/${object.index}.json`,
          {
            method: "PATCH",
            body: JSON.stringify(object.tarea),
          }
        );
        const dataDB = await res.json();
        commit("update", dataDB);
      } catch (error) {
        // console.log(error);
      }
    },
    async updateContactInfo({commit}, contactInfo) {
      try {
        const res = await fetch(
          `https://fbase-lienzo-anden-default-rtdb.firebaseio.com/data/infoContacto.json`,
          {
            method: "PATCH",
            body: JSON.stringify(contactInfo),
          }
        );
        const data = await res.json();
        commit('cargarContactInfo', data);
        router.push("/");
      } catch (error) {
        // console.log(error);
      }

    
    },
    async loadUserPreferences({ commit }) {
      if (localStorage.getItem("email")){
        commit("setEmailRemembered", JSON.parse(localStorage.getItem("email")));
      }
    },
    setCurrentRoute({ commit }, route) {
      commit('currentRoute', route);
    },
  },
  getters: {
    usuarioAutenticado(state) {
      return !!state.user;
    },
  },
  modules: {},
});
