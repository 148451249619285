<template>

  <div class="container">
    <h2 class="pt-2 mb-4">Lista de Hostspots - Anden</h2>
    <ListaTareas />
  </div>
</template>

<script>

import Input from '../components/Input'
import ListaTareas from '../components/ListaTareas'
import { mapActions } from 'vuex'
const shortid = require('shortid');

export default {
  name: 'Home',
  components: {
    Input, ListaTareas
  },
  data() {
    return {
      tarea: {
        id: '',
        manzana: '',
        lote: '',
        estado: '',
      }
    }
  },
  methods: {
    ...mapActions(['setHostpot', 'cargarLocalStorage', 'setCurrentRoute', 'getUserData']),
    procesarFormulario() {

      if (this.tarea.nombre.trim() === "") {
        return
      }


      // generar id
      this.tarea.id = shortid.generate()

      // envian los datos
      this.setTareas(this.tarea)

      // limpiar datos
      this.tarea = {
        id: '',
        nombre: '',
        categorias: [],
        estado: '',
        numero: 0
      }
    },

  },
  created() {
    this.cargarLocalStorage();
    this.setCurrentRoute('home');
    this.getUserData();
  }

}
</script>

<style scoped>
.list-item {
  margin-top: 50px;
}

.card {
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
  width: 400px;
  padding: 20px 30px;
  margin-bottom: 30px;
}
</style>
