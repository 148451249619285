<template>
    <div class="">
        <input @change="toggleTheme" id="checkbox" type="checkbox" class="switch-checkbox">
        <label for="checkbox" class="switch-label">
            <span>&nbsp;&nbsp;🌙</span>
            <span>☀️&nbsp;&nbsp;</span>
            <div class="switch-toggle" :class="{ 'switch-toggle-checked': userTheme === 'dark-theme' }"></div>
        </label>
    </div>
</template>

<script>
export default {
    mounted() {
        const initUserTheme = this.getTheme() || this.getMediaPreference();
        // this.setTheme(initUserTheme);
    },

    data() {
        return {
            userTheme: "light-theme",
        }
    },

    methods: {
        toggleTheme() {
            const activeTheme = localStorage.getItem("user-theme");
            if (activeTheme === "light-theme") {
                this.setTheme("dark-theme");
            } else {
                this.setTheme("light-theme");
            }
        },

        getTheme() {
            return localStorage.getItem("user-theme");
        },

        setTheme(theme) {
            localStorage.setItem("user-theme", theme);
            this.userTheme = theme;
            document.documentElement.className = theme;
        },

        getMediaPreference() {
            const hasDarkPreference = window.matchMedia(
                "(prefers-color-scheme: dark)"
            ).matches;
            if (hasDarkPreference) {
                return "dark-theme";
            } else {
                return "ligh-theme";
            }
        }
    },


}
</script>

<style scoped>
.switch-checkbox {
    display: none;
}

.switch-label {

    align-items: center;
    background: var(--text-primary-color);
    border: rgba(0, 0, 0, 0);
    border-radius: var(--element-size);
    cursor: pointer;
    display: flex;
    font-size: calc(var(--element-size) * 0.3);
    height: calc(var(--element-size) * 0.4);
    position: relative;
    transition: background 0.5s ease;
    justify-content: space-between;
    width: var(--element-size);
    z-index: 1;
}

.switch-toggle {
    position: absolute;
    border: 1rem var(--text-primary-color) solid;
    background-color: var(--background-color-primary);
    border-radius: 50%;
    height: calc(var(--element-size) * 0.4);
    width: calc(var(--element-size) * 0.4);
    transform: translateX(0);
    transition: transform 0.3s ease, background-color 0.5s ease;
}

.switch-toggle-checked {
    transform: translateX(calc(var(--element-size) * 0.6)) !important;
}
</style>